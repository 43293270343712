exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-dnd-encounter-creator-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/projects/dnd-encounter-creator/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-dnd-encounter-creator-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-pwa-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/projects/pwa/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-pwa-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-song-profiler-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/projects/song-profiler/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-song-profiler-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-wordpress-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/projects/wordpress/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-projects-wordpress-index-mdx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */)
}

